/* Generated by Font Squirrel (http://www.fontsquirrel.com) on August 19, 2015 */



@font-face {
    font-family: 'tg';
    src: url('./terminal-grotesque-webfont.eot');
    src: url('./terminal-grotesque-webfont.eot?#iefix') format('embedded-opentype'),
         url('./terminal-grotesque-webfont.woff2') format('woff2'),
         url('./terminal-grotesque-webfont.woff') format('woff'),
         url('./terminal-grotesque-webfont.ttf') format('truetype'),
         url('./terminal-grotesque-webfont.svg#terminal_grotesqueregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
